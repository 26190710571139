<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>代收订单</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchleft">
                            <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                                <el-form-item label="交易号">
                                    <el-input v-model="searchform.claveRastreo" style="width: 180px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item v-if="user.user.roles[0].id == 1" label="商户id">
                                    <el-input v-model="searchform.mchId" style="width: 150px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="商户订单号">
                                    <el-input v-model="searchform.mchOrderNo" style="width: 180px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item v-if="user.user.nation != 'co'" label="付款参考">
                                    <el-input v-model="searchform.cuentaBeneficiario" style="width: 180px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="渠道">
                                    <el-select v-model="searchform.channel" placeholder="请选择" @change="getchannel1">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option v-for="item in channellist" :key="item.id" :label="item.name" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="通道" v-if="user.user.roles[0].id == 1 && user.user.nation != 'co'">
                                    <el-select v-model="searchform.aisle" placeholder="请选择">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option v-for="item in channellist1" :key="item.type" :label="item.channelName" :value="item.type">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('time')">
                                    <el-date-picker
                                    v-model="value1"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item style="margin-top: 60;" label="国家" >
                                    <el-select v-model="searchform.nation" placeholder="请选择国家">
                                        <el-option :label="$t('buxian')" :value="null"></el-option>
                                        <el-option v-for="item in nationlist" :key="item.values" :label="item.name" :value="item.values"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                                    <el-button size="small" type="primary" @click="download">导出</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="createTime1" label="收款时间" :width="160"></el-table-column>
                        <el-table-column prop="channelName" label="渠道" width="88"></el-table-column>
                        <el-table-column prop="orgId" label="商户id" width="118"></el-table-column>
                        <el-table-column prop="claveRastreo" label="交易号" width="188"></el-table-column>
                        <el-table-column prop="mchOrderNo" label="商户订单号" width="188"></el-table-column>
                        <el-table-column prop="monto" label="收支金额" width="88"></el-table-column>
                        <el-table-column prop="papeleta" label="实收金额(mx)" width="128"></el-table-column>
                        <el-table-column prop="cost" label="服务费(mx)" width="118"></el-table-column>
                        <el-table-column prop="balance" label="商户入账(mx)" width="88"></el-table-column>
                        <el-table-column prop="status" label="状态" width="118">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status==1" type="primary" size="mini" title="支付成功">支付成功</el-tag>
                                <el-tag v-if="scope.row.status==2" type="warning" size="mini" :title=scope.row.remarks>支付拒绝</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cuentaBeneficiario" label="付款参考" width="158"></el-table-column>
                        <!-- <el-table-column v-if="user.user.id == 38 && user.user.nation == 'mx'" prop="cuentaOrdenante" label="付款人账号" width="158"></el-table-column> -->
                        <el-table-column prop="describe" label="描述" width="88"></el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1 && user.user.nation != 'co'" prop="channel" label="通道" width="100"></el-table-column>
                        <el-table-column prop="nation" label="国家" width="88"></el-table-column>
                        <el-table-column fixed="right" v-if="user.user.nation != 'co'" prop="jobName" :label="$t('operator')" width="158">
                            <template slot-scope="scope">
                                <el-popconfirm title="确定退款吗？" v-if="user.user.roles[0].id == 1 && scope.row.status == 1
                                && scope.row.orgId != '4761266111' 
                                && scope.row.orgId != '4654913182'
                                && scope.row.orgId != '9125191611'
                                "
                                    @confirm="cobrador(scope.row)" content="审核通过">
                                    <el-button size="small" type="warning" icon="el-icon-check" circle slot="reference" plain></el-button>
                                </el-popconfirm>
                                <el-button v-if="scope.row.status == 1 && scope.row.receiptUrl != null" size="small" 
                                    title="查看凭证" @click="queryPayLink(scope.row)" type="primary" icon="el-icon-view" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                                <el-button v-if="scope.row.channel == 'oxxo_stripe' || scope.row.channel == 'tarjeta_stripe'" size="small" 
                                    title="查看用户信息" @click="queryUserInfo(scope.row)" type="primary" icon="el-icon-view" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page">
                        <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                            @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import fileDownload from "js-file-download";
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                form: {
                    orderId: null,
                    money: null,
                },
                loading: false,
                showadd: false,
                list: [],
                searchform:{},
                total: 1,
                pageNo: 1,
                pageSize: 10,
                value1: null,
                nation: null,
                channellist: [],
                channellist1: [],
                nationlist: [],
            }
        },
        created() {
            this.user=JSON.parse(localStorage.getItem("userinfo"))
            this.value1=this.getNowTime() 
            this.getList()
            this.getchannel()
            this.getnation()
        },
        methods: {
            getnation() {
                _api.get(apiurl.nationlist, {}).then(res => {
                    let newarr = []
                    if (this.user.user.roles[0].id != 1) {
                        const nations = this.user.user.nation.split(',')
                        for (let value of nations) {
                            for(let item of res.data){
                                if (item.values ===  value) {
                                    newarr.push(item)
                                }
                            }
                        }
                    } else {
                        for(let item of res.data){
                            newarr.push(item)
                        }
                    }
                    this.nationlist = newarr
                })
            },
            queryPayLink(row){
                window.open(row.receiptUrl, '_blank');
            },
            queryUserInfo(row){
                this.$alert('邮箱：' + row.userEmail + "  姓名：" + row.userName, '用户信息', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$message({
                    type: 'info',
                        message: `action: ${ action }`
                    });
                }
                });
            },
            cobrador(row) {
                _api.get(apiurl.refund, {
                    audId: row.id,
                    status: 2,
                }).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('success'),
                        type: 'success'
                    });
                    this.getList()
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            getNowTime() {
                const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 2)
                    .toISOString()
                    .replace('T', ' ')
                    .split('.')[0] //默认开始时间7天前
                const end = new Date(new Date().getTime())
                    .toISOString()
                    .replace('T', ' ')
                    .split('.')[0]//默认结束时间1天前
                return [start, end]
            },
            getchannel() {
                _api.get(apiurl.channellist, {}).then(res => {
                    let newarr = []
                    for(let item of res.data){
                        newarr.push(item)
                    }
                    this.channellist = newarr
                })
            },
            getchannel1() {
                if (this.searchform.channel > 0) {
                    _api.get(apiurl.subChannelList, {
                        type: this.searchform.channel,
                    }).then(res => {
                        this.channellist1 = res.data
                        if (res.data == "") {
                            this.searchform.aisle = null
                        }
                    })
                } else {
                    this.channellist1 = null
                }
            },
            getList() {
                this.loading = true
                _api.get(apiurl.repayment, {
                    page: this.pageNo,
                    size: this.pageSize,
                    orderNo: this.searchform.claveRastreo,
                    createTime: this.value1,
                    channel: this.searchform.channel,
                    cuentaBeneficiario: this.searchform.cuentaBeneficiario,
                    mchId: this.searchform.mchId,
                    aisle: this.searchform.aisle,
                    mchOrderNo: this.searchform.mchOrderNo,
                    nation: this.searchform.nation,
                }).then(res => {
                    this.loading = false
                    this.list = res.data.data
                    this.total = res.data.totalCount
                })
            },
            download() {
                _api.download(apiurl.download1, {
                    orderNo: this.searchform.claveRastreo,
                    createTime: this.value1,
                    channel: this.searchform.channel,
                    cuentaBeneficiario: this.searchform.cuentaBeneficiario,
                    mchId: this.searchform.mchId,
                    aisle: this.searchform.aisle,
                    mchOrderNo: this.searchform.mchOrderNo,
                    nation: this.searchform.nation,
                }).then(res => {
                    let a = document.createElement('a');
                    let blob = new Blob([res], {type: "application/vnd.ms-excel"});
                    let objectUrl = URL.createObjectURL(blob);
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '代收订单数据.xls');
                    a.click();
                })
            },
            onSubmit() {
                this.pageNo = 1
                this.getList()
            },
            handchange(data) { //分页Size变化
                this.pageSize = data
                this.pageNo = 1
                this.getList()
            },
            currentchange(data) { //当前页变化
                this.pageNo = data
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>